import React, { useMemo } from "react";
import { connect } from "react-redux";
import tvgConf from "@tvg/conf";

import { format } from "date-fns";
import { get, noop } from "lodash";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import ResponsibleGamingModal from "@tvg/atomic-ui/_templates/ResponsibleGamingModal";
import parseCAPIMessage from "@tvg/utils/capiUtils";
import { openExternalApp } from "@tvg/utils/mediatorUtils";
import calcModalContainerOffset from "@tvg/utils/modalUtils";
import formatCurrency from "@tvg/formatter/currency";
import mediator from "@tvg/mediator";
import RG_OPTIONS from "@tvg/types/ResponsibleGaming";

import { MODAL_TITLE_TYPE } from "./types";

import {
  rgCloseModal,
  rgModalClickGTM,
  rgSubmitModalOpen,
  rgCancelModalOpen
} from "./actions";

export const getTitleColor = (status) => {
  switch (status) {
    case "success":
      return MODAL_TITLE_TYPE.SUCCESS;
    case "error":
      return MODAL_TITLE_TYPE.ERROR;
    default:
      return MODAL_TITLE_TYPE.DEFAULT;
  }
};

export const getModalTitle = (status, titleMessages) =>
  get(titleMessages, `title.${status}`);

export const getModalType = ({
  device,
  type,
  isProcessing,
  messages,
  status,
  dailyLimit,
  oldDailyLimit,
  appliedDate,
  titleType,
  closeModal,
  fundingMethod,
  timeoutPeriod,
  returnDate,
  submit,
  cancel,
  customerServicesUrl
}) => {
  let modalProps = {
    type,
    isProcessing,
    fundingMethod,
    timeoutPeriod,
    returnDate: returnDate && format(new Date(returnDate), "MM/dd/yyyy"),
    qaLabel: status,
    error: get(messages, "error", ""),
    messages: get(messages, "messages", []),
    hasError: false,
    depositLimitStatus: [
      {
        name: get(messages, "values.limit", ""),
        value:
          !dailyLimit || isNaN(dailyLimit)
            ? dailyLimit
            : formatCurrency(+dailyLimit),
        previous:
          !oldDailyLimit || isNaN(oldDailyLimit)
            ? oldDailyLimit
            : formatCurrency(+oldDailyLimit),
        type: "currency"
      },
      {
        name: get(messages, "values.applied", ""),
        value: appliedDate,
        previous: undefined,
        type: "date"
      }
    ],
    isFinished: titleType !== MODAL_TITLE_TYPE.DEFAULT,
    primaryButton: submit,
    primaryButtonText: get(messages, "buttons.confirmation", ""),
    secondaryButton: cancel,
    secondaryButtonText: get(messages, "buttons.cancel", "")
  };
  // eslint-disable-next-line default-case
  switch (status) {
    case "success":
      modalProps = {
        ...modalProps,
        primaryButton: (event) => {
          rgModalClickGTM(type, "return to homepage");
          closeModal();

          if (tvgConf().product === "iosnative") {
            event.preventDefault();
            event.stopPropagation();
            if (window && window.handleNativeMessages) {
              window.handleNativeMessages("UPDATE_STATUS_BAR_COLOR", "light");
              window.handleNativeMessages("GO_TO_HOME");
            }
          } else if (device === "desktop") {
            mediator.base.dispatch({
              type: "TVG4_NAVIGATION",
              payload: { route: "/" }
            });
          }
        },
        primaryButtonText: get(messages, "buttons.homepage", ""),
        primaryButtonUrl: "/",
        secondaryButtonText: get(messages, "buttons.back", "")
      };
      break;
    case "error":
      modalProps = {
        ...modalProps,
        hasError: true,
        primaryButton: () => {
          submit();
          rgModalClickGTM(type, "open support");
          openExternalApp(customerServicesUrl);
        },
        secondaryButton: () => {
          cancel();
          rgModalClickGTM(type, "back");
          closeModal();
        },
        primaryButtonText: get(messages, "buttons.contactUs", ""),
        secondaryButtonText: get(messages, "buttons.back", "")
      };
      break;
  }

  return modalProps;
};

export const ResponsibleGamingModalComponent = ({
  isOpen,
  closeModal,
  type,
  rgModalMessages,
  device,
  status,
  dailyLimit,
  oldDailyLimit,
  appliedDate,
  fundingMethod,
  timeoutPeriod,
  returnDate,
  submit,
  cancel,
  customerServicesUrl,
  isProcessing
}) => {
  const isMobile = device === "mobile";
  const offsetDesktop = isMobile ? 0 : calcModalContainerOffset();
  const titleType = getTitleColor(status);
  const messages = get(rgModalMessages, type, {});

  return useMemo(
    () => (
      <ModalV2
        device={device}
        isOpen={isOpen}
        onClose={() => {
          rgModalClickGTM(type, "close");
          closeModal();
        }}
        title={getModalTitle(status, messages)}
        qaLabel="responsible-gaming-modal"
        animation={isMobile ? "bottom" : "fade"}
        titleType={titleType}
        offsetLeft={offsetDesktop}
        offsetRight={offsetDesktop}
        isFullWidth={isMobile}
        fixedWidth={isMobile ? "100%" : "375px"}
        isFullHeight={false}
        hasHeaderIcon={false}
        hasCloseButton={status !== "error"}
      >
        {() => (
          <ResponsibleGamingModal
            {...getModalType({
              device,
              type,
              messages,
              status,
              dailyLimit,
              oldDailyLimit,
              appliedDate,
              titleType,
              closeModal,
              fundingMethod,
              timeoutPeriod,
              returnDate,
              submit,
              cancel,
              customerServicesUrl,
              isProcessing
            })}
          />
        )}
      </ModalV2>
    ),
    [
      type,
      status,
      dailyLimit,
      oldDailyLimit,
      appliedDate,
      fundingMethod,
      isOpen,
      JSON.stringify(rgModalMessages),
      isProcessing
    ]
  );
};

ResponsibleGamingModalComponent.defaultProps = {
  isOpen: false,
  submit: noop,
  cancel: noop,
  closeModal: noop,
  dispatch: noop,
  type: RG_OPTIONS.DEPOSIT_LIMIT,
  rgModalMessages: {},
  device: "mobile",
  status: "confirmation",
  dailyLimit: "$0",
  oldDailyLimit: null,
  appliedDate: "",
  fundingMethod: "",
  timeoutPeriod: "",
  returnDate: "",
  customerServicesUrl: "",
  isProcessing: false
};

const mapStateToProps = (store) => ({
  isOpen: get(store, "modal.responsibleGaming.isOpen", false),
  type: get(store, "modal.responsibleGaming.type", RG_OPTIONS.DEPOSIT_LIMIT),
  isProcessing: get(store, "modal.responsibleGaming.isProcessing", false),
  status: get(store, "modal.responsibleGaming.status", "confirmation"),
  dailyLimit: get(store, "modal.responsibleGaming.dailyLimit.new", "$0"),
  oldDailyLimit: get(store, "modal.responsibleGaming.dailyLimit.current", null),
  appliedDate: get(store, "modal.responsibleGaming.dailyLimit.appliedDate", ""),
  fundingMethod: get(store, "modal.responsibleGaming.fundingMethod", ""),
  timeoutPeriod: get(store, "modal.responsibleGaming.timeoutPeriod.period", ""),
  returnDate: get(store, "modal.responsibleGaming.timeoutPeriod.endDate", ""),
  rgModalMessages: parseCAPIMessage(store, "capi.messages.rgModalMessages", {})
});

export const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(rgCloseModal()),
  submit: rgSubmitModalOpen,
  cancel: rgCancelModalOpen
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResponsibleGamingModalComponent);
