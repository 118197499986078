import mediator from "@tvg/mediator";

export const rgSetCallback = (callback) => ({
  type: "GET_RG_MODAL_CALLBACK",
  payload: {
    callback
  }
});

const setCallback = (callback) => {
  if (typeof callback === "function") {
    mediator.base.dispatch(rgSetCallback(callback));
  }
};

export const rgSetCallbackSecondary = (callback) => ({
  type: "GET_RG_MODAL_CALLBACK_SECONDARY",
  payload: {
    callback
  }
});

const setCallbackSecondary = (callback) => {
  if (typeof callback === "function") {
    mediator.base.dispatch(rgSetCallbackSecondary(callback));
  }
};

export const rgClearCallbacks = () => ({
  type: "RG_MODAL_CLEAR_CALLBACKS"
});

const clearCallbacks = () => {
  mediator.base.dispatch(rgClearCallbacks());
};

export const rgDepositLimitModalOpen = (
  newLimit,
  appliedDate,
  currentLimit,
  primaryCallback,
  secondaryCallback
) => {
  clearCallbacks();
  if (primaryCallback) setCallback(primaryCallback);
  if (secondaryCallback) setCallbackSecondary(secondaryCallback);
  return {
    type: "RG_MODAL_DEPOSIT_LIMIT_OPEN",
    payload: {
      newLimit,
      currentLimit,
      appliedDate
    }
  };
};

export const rgFundingControlModalOpen = (
  fundingMethod,
  primaryCallback,
  secondaryCallback
) => {
  clearCallbacks();
  if (primaryCallback) setCallback(primaryCallback);
  if (secondaryCallback) setCallbackSecondary(secondaryCallback);
  return {
    type: "RG_MODAL_FUNDING_CONTROL_OPEN",
    payload: {
      fundingMethod
    }
  };
};

export const rgTimeoutModalOpen = (
  period,
  endDate,
  primaryCallback,
  secondaryCallback
) => {
  clearCallbacks();
  if (primaryCallback) setCallback(primaryCallback);
  if (secondaryCallback) setCallbackSecondary(secondaryCallback);
  return {
    type: "RG_MODAL_TIMEOUT_OPEN",
    payload: {
      period,
      endDate
    }
  };
};

export const rgSelfExclusionModalOpen = (
  period,
  endDate,
  primaryCallback,
  secondaryCallback
) => {
  clearCallbacks();
  if (primaryCallback) setCallback(primaryCallback);
  if (secondaryCallback) setCallbackSecondary(secondaryCallback);
  return {
    type: "RG_MODAL_SELF_EXCLUSION_OPEN",
    payload: {
      period,
      endDate
    }
  };
};

export const rgSuspensionModalOpen = (primaryCallback, secondaryCallback) => {
  clearCallbacks();
  if (primaryCallback) setCallback(primaryCallback);
  if (secondaryCallback) setCallbackSecondary(secondaryCallback);
  return {
    type: "RG_MODAL_SUSPENSION_OPEN"
  };
};

export const rgSubmitModalOpen = () =>
  mediator.base.dispatch({
    type: "RG_MODAL_SUBMIT"
  });

export const rgCancelModalOpen = () =>
  mediator.base.dispatch({
    type: "RG_MODAL_CANCEL"
  });

export const rgSubmitModalError = (primaryCallback, secondaryCallback) => {
  clearCallbacks();
  if (primaryCallback) setCallback(primaryCallback);
  if (secondaryCallback) setCallbackSecondary(secondaryCallback);
  return {
    type: "RG_MODAL_ERROR"
  };
};

export const rgSubmitModalFinished = (primaryCallback, secondaryCallback) => {
  clearCallbacks();
  if (primaryCallback) setCallback(primaryCallback);
  if (secondaryCallback) setCallbackSecondary(secondaryCallback);
  return {
    type: "RG_MODAL_FINISHED"
  };
};

export const rgCloseModal = () => {
  clearCallbacks();

  return {
    type: "RG_MODAL_CLOSE"
  };
};

export const rgSetLoading = (isProcessing) => ({
  type: "RG_MODAL_PROCESSING",
  payload: {
    isProcessing
  }
});

export const rgModalClickGTM = (module, gaEventLabel) => {
  mediator.base.dispatch({
    type: "RG_MODAL_CLICK_HANDLE",
    payload: {
      module,
      gaEventLabel
    }
  });
};
